import { AfterViewInit, Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    }
  ],
})
export class DateInputComponent implements ControlValueAccessor, AfterViewInit {
  public _value: string | null = null;
  public disabled: boolean = false;
  @Input() value: string | null = null;
  @Input() title!: string;
  @Input() placeholder!: string;
  @Input() valid: boolean = false;
  @Input() time: boolean = false;
  @Input() optional: boolean = false;
  @Input() invalidInputMsg: string = 'Invalido'; // = this.translate.instant('invalid.input');
  hourInputId = Math.random().toString(36).substr(2, 9);

  onChanged: (value: string | null) => void = () => { };
  onTouched: () => void = () => { };

  constructor(private translate: TranslateService) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this._value) {
        this.extractHoursAndMinutes(this._value);
      }
    });
  }

  writeValue(val: string | null): void {
    this._value = val;
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectDate(event: MatDatepickerInputEvent<Date>): void {
    const date = event.value;
    if (!date) return;

    const isoDateString = date.toISOString();
    const finalDateValue = this.time ? this.addTimeToDateString(isoDateString) : isoDateString;

    this.extractHoursAndMinutes(finalDateValue);
    this.writeValue(finalDateValue);
    this.onChanged(finalDateValue);
    this.onTouched();
  }

  addTimeToDateString(isoDateString: string): string {
    const timeInput = document.getElementById(this.hourInputId) as HTMLInputElement;
    if (!timeInput || timeInput.value.trim() === '') {
      this.valid = false;
      const date = new Date(isoDateString);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    }

    const timeValue = timeInput.value.trim();
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (!regex.test(timeValue)) {
      this.valid = true;
      this.invalidInputMsg = this.translate.instant('hour.invalid');
      const date = new Date(isoDateString);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    }

    this.valid = false;

    const timeParts = timeValue.split(':');
    const hours = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    if (minutes < 0 || minutes > 59) {
      this.valid = true;
      this.invalidInputMsg = this.translate.instant('hour.invalid');
      const date = new Date(isoDateString);
      date.setHours(0, 0, 0, 0);
      return date.toISOString();
    }

    const date = new Date(isoDateString);
    date.setHours(hours, minutes, 0, 0);

    return date.toISOString();
  }

  extractHoursAndMinutes(isoDateString: string): string {
    const date = new Date(isoDateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = hours < 10 ? '0' + hours : hours.toString();
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const timeInput = document.getElementById(this.hourInputId) as HTMLInputElement;
    if (timeInput) {
      timeInput.value = `${formattedHours}:${formattedMinutes}`;
    }
    return `${formattedHours}:${formattedMinutes}`;
  }

  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let sanitizedValue = input.value.replace(/\D/g, '');
    if (sanitizedValue.length > 4) {
      sanitizedValue = sanitizedValue.substring(0, 4);
    }
    if (sanitizedValue.length > 2) {
      sanitizedValue = `${sanitizedValue.substring(0, 2)}:${sanitizedValue.substring(2)}`;
    }
    input.value = sanitizedValue;
    this._value = this.addTimeToDateString(this._value || new Date().toISOString());
    this.onChanged(this._value);
    this.onTouched();
  }
}
