import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slide-toggle-input',
  templateUrl: './slide-toggle-input.component.html',
  styleUrls: ['./slide-toggle-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleInputComponent),
      multi: true,
    }
  ],
})
export class SlideToggleInputComponent implements ControlValueAccessor {
  public _value: boolean = false;
  public disabled: boolean = false;
  @Input() label: string = '';
  @Input() value: boolean = false;
  @Input() toppings = new FormControl<boolean>(false);

  onChanged: (value: boolean) => void = () => { };
  onTouched: () => void = () => { };

  writeValue(val: boolean): void {
    this._value = val;
    this.toppings.setValue(val);
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  checked(): void {
    const newValue = !this.toppings.value;
    this.toppings.setValue(newValue);
    this.value = newValue;
    this.onTouched();
    this.onChanged(newValue);
  }
}
