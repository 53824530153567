import { Injectable } from '@angular/core';
import { MeService } from '../ws-user/me/me.service';
import { Me } from '@model/ws-user';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(private meService: MeService) { }

  getUserPermission(route: string, me: Me) {
    if (route.includes('products')) {
      return me.permissions.some((permission: string) => (permission.includes('product.create') || permission.includes('products.create')));
    } else if (route.includes('categories')) {
      return me.permissions.some((permission: string) => permission.includes('common.category.create') || permission.includes('common.create'));
    } else if (route.includes('contents')) {
      return me.permissions.some((permission: string) => permission.includes('contents.content.create') || permission.includes('contents.create'));
    } else if (route.includes('users')) {
      return me.permissions.some((permission: string) => (permission.includes('users.create') || permission.includes('users.user.create')));
    } else if (route.includes('events/requests')) {
      return me.permissions.some((permission: string) => permission.includes('participant.create'));
    } else if (route.includes('events')) {
      return me.permissions.some((permission: string) => (permission.includes('event.create') || permission.includes('eventhubs.create')));
    } else {
      return me.permissions.some((p: string) => (p.includes('event.create') || p.includes('eventhubs.create')) || p.includes('participant.create') || p.includes('users.create') || p.includes('users.user.create') || p.includes('contents.content.create') || p.includes('common.category.create') || p.includes('product.create') || p.includes('products.create'));
    }
  }

  getUser() {
    return this.meService.getMeCache();
  }

  hasPermission(permissionKey: string) {
    return this.meService.getMeCache().pipe(
      // map(me => this.checkPermission(me, permissionKey))
    );
  }

}
