import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Attachment } from '@model/Attachment.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialogs/dialog.service';

@Component({
  selector: 'app-upload-file-input',
  templateUrl: './upload-file-input.component.html',
  styleUrls: ['./upload-file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadFileInputComponent),
      multi: true,
    }
  ],
})
export class UploadFileInputComponent implements ControlValueAccessor {
  @ViewChild('fileInputRef', { static: false }) fileInput!: ElementRef<HTMLInputElement>;
  files: Attachment[] = [];
  error: string = '';
  @Input() highlight: boolean = false;
  @Input() optional: boolean = false;

  private onChange: (value: Attachment[] | null) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(private translate: TranslateService, private dialog: DialogService) { }

  selectFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    if (file.size > 30 * 1024 * 1024) {
      this.error = this.translate.instant('file.size.invalid');
      this.onChange(null);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const result = event.target?.result as string;

      if (!result.startsWith("data:application/pdf")) {
        this.error = this.translate.instant('file.type.not');
        this.onChange(null);
      } else {
        this.files.push({ file: result, title: file.name });
        this.error = '';
        this.onChange(this.files);
      }
    };
  }

  deleteFile(file: Attachment): void {
    this.dialog.confirmDialog({
      title: this.translate.instant('file.delete'),
      message: this.translate.instant('file.delete'),
      confirmCaption: this.translate.instant('yes'),
      cancelCaption: this.translate.instant('no'),
    }).subscribe((confirmed) => {
      if (confirmed) {
        const index = this.files.indexOf(file);
        if (index !== -1) {
          this.files.splice(index, 1);
        }
        this.onChange(this.files);
        this.fileInput.nativeElement.value = '';
      }
    });
  }

  writeValue(value: Attachment[]): void {
    if (value) {
      this.files = value;
    }
  }

  registerOnChange(fn: (value: Attachment[] | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
