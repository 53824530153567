import { Component, OnInit } from '@angular/core';

import packageJson from '../../package.json';
import { environment } from '@env/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    if (environment.enableTestLogs) {
      console.log(`APP VERSION: ${packageJson.version}`);
    }
  }
}
