import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { NavbarService } from './services/navbar/navbar.service';
import { AuthserviceService } from './services/authservice.service';
import { Me } from '@model/ws-user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Backoffice';
  isLoggedIn = true;

  isSidebarOpen = false;
  private subscription!: Subscription;

  user: Me = {} as Me;

  constructor(
    private authService: AuthserviceService,
    private navbarService: NavbarService) { }

  ngOnInit() {
    this.user = this.authService.getUser();
    this.subscription = this.navbarService.sidebarState$.subscribe(
      isSidebarOpen => {
        this.isSidebarOpen = isSidebarOpen;
      }
    );

    this.isLoggedIn = this.user != {} as any;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
