import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ApiResponse } from '@model/api-response.model';
import { Country } from '@model/ws-common/country.model';
import { City } from '@model/ws-common/city.model';

const URL = `${environment.common}/location`;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private http: HttpClient) { }

  getCountries(): Observable<ApiResponse<Country[]>> {
    return this.http.get<ApiResponse<Country[]>>(`${URL}/country?PageSize=0`);
  }

  getCities(countryId: string) {
    return this.http.get<ApiResponse<City[]>>(`${URL}/country/${countryId}/location`);
  }

  getInfoByCityId(cityId: string) {
    return this.http.get<ApiResponse<City>>(`${URL}/location/${cityId}`);
  }
}
