import { Component, Input } from '@angular/core';
import { EventParticipantDetails } from '@model/ws-eventshub/event-manage.model';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrl: './table-card.component.scss'
})
export class TableCardComponent {

  @Input() event!: EventParticipantDetails;
}
