import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';

import { CustomPaginator } from 'src/app/utils/CustomPaginatorConfiguration';
import { LocaleService } from 'src/app/services/locale.service';
import { UserActions } from 'src/app/models/enums/UserActions';
import { TableColumns } from 'src/app/models/tablecolumns';
import { TableActions } from 'src/app/models/tableActions';
import { CustomFilterComponent } from '../custom-filter/custom-filter.component';
import { MeService } from 'src/app/services/ws-user/me/me.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from 'src/app/services/dialogs/dialog.service';
import { ProductManage } from '@model/ws-product/manage/product-manage.model';
import { EventParticipantDetails } from '@model/ws-eventshub/event-manage.model';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
  providers: [
    { provide: MatPaginatorIntl, useValue: CustomPaginator() }
  ]
})
export class CustomTableComponent implements OnChanges, OnInit {

  constructor(
    private localeService: LocaleService,
    public dialog: MatDialog,
    private meService: MeService,
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private alert: DialogService
  ) {
    this.userLocale = this.localeService.getLocale();
  }

  @Input() tableTitle = '';
  @Input() addButtonText = '';
  @Input() tableDetails!: EventParticipantDetails;
  @Input() backButton = false;
  @Input() backRoute!: any;
  @Input() tableColumn: TableColumns[] = [];
  @Input() tableAction?: TableActions;
  @Input() data: any[] = [];
  @Input() isDataLoaded = false;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() readonly pageEventCalled = new EventEmitter();
  @Output() readonly actionCalled = new EventEmitter();
  @Output() readonly dragDropped = new EventEmitter<any>();
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  columnObjects!: TableColumns[];
  actionObjects!: TableActions;
  columnNames: string[] = [];
  columnFilters: string[] = [];
  readonly userActions: typeof UserActions = UserActions;
  readonly objectKeys = Object.keys;
  userLocale: string;
  dateRange = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  pageSizeOptions: number[] = [10, 25, 50, 100];
  pageProperties: PageEvent = {
    length: 0,
    pageIndex: 0,
    pageSize: 10,
  };
  unfilteredData = this.dataSource.filteredData;
  me!: any;
  createButton = true;
  deleteButton = true;
  @Input() unselectAllCheckbox = false;

  ngOnInit(): void {

    this.meService.getMeCache().subscribe({
      next: (res) => this.me = res.data,
      error: (er) => this.snackBar.open(er.error.errors[0], this.translate.instant('close'), { duration: 5000 })
    })
    this.columnObjects = this.tableColumn.map(json => TableColumns.fromJson(json));
    this.actionObjects = this.tableAction ?? new TableActions();
    this.columnNames = [
      'checkbox',
      ...this.tableColumn.map((col: TableColumns) => col.name),
      'actions',
    ];
    this.columnFilters = this.columnObjects
    .filter((col: TableColumns) => col.filterable)
    .map((col: TableColumns) => col.name) ?? [];
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return this.columnFilters.some(column => {
        const value = (data[column] ?? '').toString();
        const normalizedValue = this.normalizeString(value);
        const normalizedFilter = this.normalizeString(filter);
        return normalizedValue.includes(normalizedFilter);
      });
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSource.data = this.data;
    this.dataSource.sort = this.sort;
    /*Sobrescreve o sorting do material*/
    this.dataSource.sortingDataAccessor = (item, property) => {
      if (property === 'created' || property === 'updated') {
        const dateTimeString = item[property] ? item[property].split(' ').slice(0, 2).join(' ') : '';
        const dateParts = dateTimeString.split(' ');
        const date = dateParts[0].split('/').reverse().join('-');
        const time = dateParts[1];
        const dateTime = new Date(`${date}T${time}`).getTime();
        return dateTime;
      }
      return typeof item[property] === 'string' ? item[property].toLowerCase() : item[property];
    };
    this.dataSource.paginator = this.paginator;
    if (this.me) {
      this.updateButtonState('create', 'createButton');
      this.updateButtonState('delete', 'deleteButton');
    }
    if (changes['unselectAllCheckbox']?.currentValue) {
      this.selection.clear();
    }
  }

  selection = new Set<any>();
  toggleSelection(item: any): void {
    if (this.selection.has(item)) {
      this.selection.delete(item);
    } else {
      this.selection.add(item);
    }
  }

  toggleAll(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((item) => this.selection.add(item));
    }
  }

  isAllSelected(): boolean {
    return this.selection.size === this.dataSource.data.length;
  }

  isSomeSelected(): boolean {
    return this.selection.size > 0 && !this.isAllSelected();
  }

  drop(event: CdkDragDrop<ProductManage[]>): void {
    if (this.tableTitle === 'products') {
      const pageIndex = this.paginator.pageIndex;
      const pageSize = this.paginator.pageSize;
      const previousIndex = pageIndex * pageSize + event.previousIndex;
      const currentIndex = pageIndex * pageSize + event.currentIndex;
      moveItemInArray(this.data, previousIndex, currentIndex);
      this.dataSource.data = [...this.data];
      const draggedItem = {
        id: this.data[currentIndex].id,
        order: currentIndex + 1,
        orderPrevious: previousIndex + 1
      };
      this.dragDropped.emit(draggedItem);
    }
  }

  restoreOriginalOrder(product: any): void {
    moveItemInArray(this.data, product.order - 1, product.orderPrevious - 1);
    this.dataSource.data = [...this.data];
  }

  normalizeString(input: string): string {
    return input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  onFilter(e: Event) {
    const filterValue = (e.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSource.filter = filterValue;
    this.paginator.firstPage();
  }

  isFilterActive() {
    return !!this.dataSource.filter;
  }

  isTableSorted(): boolean {
    return this.sort ? (!!this.sort.active && !!this.sort.direction) : false;
  }

  performAction(action: string, obj?: any) {
    if (!this.actionCalled) {
      return;
    } else if (action === this.userActions.Delete) {
      obj = this.selection;
      this.alert.confirmDialog({
        title: this.translate.instant('tab.close'),
        message: this.translate.instant('delete.confirm'),
        confirmCaption: this.translate.instant('yes'),
        cancelCaption: this.translate.instant('no'),
      })
        .subscribe((yes) => {
          if (yes) {
            this.actionCalled.emit({
              action,
              obj,
            });
          } else {
            return
          }
        });
    } else {
      this.actionCalled.emit({
        action,
        obj,
      });
    }
  }
  // private closingTabAlert(tab: any) {
  //   this.dialog.confirmDialog({
  //     title: this.translate.instant('tab.close'),
  //     message: this.translate.instant('tab.close.confirm'),
  //     confirmCaption: this.translate.instant('yes'),
  //     cancelCaption: this.translate.instant('no'),
  //   })
  //     .subscribe((yes) => {
  //       if (yes) {
  //         this.existingTabs.length === 1 ? this.dialogRef.close() : this.closeTab(tab);
  //       }
  //     });
  // }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  formatValue(value: any, type: string | undefined, format: string | undefined): string {
    if (value === null || value === undefined) {
      return null!;
    }
    else if (value[0]?.title) {
      return value.map((v: any) => v.title);
    }
    else if (Array.isArray(value)) {
      return value.join(', ');
    }
    else if (type === 'currency') {
      return new Intl.NumberFormat(format ?? 'USD', { style: 'currency', currency: format ?? 'USD' }).format(value);
    }
    else if (type === 'date') {
      return formatDate(value, format ?? 'short', this.userLocale || 'en-US');
    }
    else {
      return value;
    }
  }

  updateButtonState(actionType: 'create' | 'delete', buttonType: 'createButton' | 'deleteButton'): void {
    const permissionsMap: { [key: string]: string[] } = {
      categories: [`common.category.${actionType}`, `common.${actionType}`],
      products: [`products.product.${actionType}`, `products.${actionType}`],
      events: [`eventhubs.event.${actionType}`, `eventhubs.${actionType}`],
      contents: [`contents.content.${actionType}`, `contents.${actionType}`],
      users: [`users.user.${actionType}`, `users.${actionType}`],
    };

    this[buttonType] = permissionsMap[this.tableTitle]
      ? this.checkPermission(permissionsMap[this.tableTitle])
      : false;
  }

  private checkPermission(permissionKeys: string[]): boolean {
    return !(permissionKeys.some(key => this.me.permissions.includes(key)));
  }

  onCustomFilter() {
    const dialogRef = this.dialog.open(CustomFilterComponent, {
      minWidth: '400px',
      data: {
        // item: item,
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //
      }
    });
  }

  isIdColumn(name: string) {
    return name === 'id';
  }

  updateEventDetails(e: any) {
    this.actionCalled.emit(e);
  }
}
