import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Attachment } from '@model/Attachment.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'src/app/services/dialogs/dialog.service';

@Component({
  selector: 'app-upload-image-input',
  templateUrl: './upload-image-input.component.html',
  styleUrls: ['./upload-image-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UploadImageInputComponent),
      multi: true,
    }
  ],
})
export class UploadImageInputComponent implements ControlValueAccessor {
  @ViewChild('picInputRef', { static: false }) picInput!: ElementRef<HTMLInputElement>;
  picture: Attachment | null = null;
  multiplePicture: Attachment[] = [];
  error: string = '';
  @Input() highlight: boolean = false;
  @Input() optional: boolean = false;
  @Input() multiple: boolean = false;
  @Input() valid: boolean = false;
  @Input() hasName: boolean = true;
  @Input() invalidInputMsg: string = this.translate.instant('invalid.input');

  private onChange: (value: Attachment | Attachment[] | null) => void = () => { };
  private onTouched: () => void = () => { };

  constructor(private translate: TranslateService, private dialog: DialogService) { }

  changeImage(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) return;

    if (file.size >= 5242880) {
      this.error = this.translate.instant('image.size.5mb');
      this.onChange(null);
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const result = event.target?.result as string;

      if (!result.startsWith("data:image/") || result.startsWith("data:image/tiff") || result.startsWith("data:image/svg+xml")) {
        this.error = this.translate.instant('image.type.not');
        this.onChange(null);
      } else {
        const newImage: Attachment = {
          file: result,
          title: file.name,
          highlight: this.highlight,
        };

        if (this.multiple) {
          this.multiplePicture.push(newImage);
          this.onChange(this.multiplePicture);
        } else {
          this.picture = this.hasName ? newImage : null;
          this.onChange(this.picture);
        }
        this.error = '';
      }
    };
  }

  deleteImage(image: Attachment): void {
    this.dialog.confirmDialog({
      title: this.translate.instant('image.delete'),
      message: this.translate.instant('image.delete'),
      confirmCaption: this.translate.instant('yes'),
      cancelCaption: this.translate.instant('no'),
    }).subscribe((confirmed) => {
      if (confirmed) {
        if (this.multiple) {
          const index = this.multiplePicture.indexOf(image);
          if (index !== -1) {
            this.multiplePicture.splice(index, 1);
          }
          this.onChange(this.multiplePicture);
        } else {
          this.picture = null;
          this.onChange(null);
        }
        this.picInput.nativeElement.value = '';
      }
    });
  }

  writeValue(value: Attachment | Attachment[] | null): void {
    if (value) {
      if (this.multiple && Array.isArray(value)) {
        this.multiplePicture = value;
      } else if (!this.multiple && !Array.isArray(value)) {
        this.picture = value;
      }
    }
  }

  registerOnChange(fn: (value: Attachment | Attachment[] | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  touched(): void {
    this.onTouched();
  }
}
