<mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input">
  <label class="label">{{ label }}</label>
  <mat-select multiple [formControl]="toppings" [placeholder]="placeholder" [(ngModel)]="defaultValues">
    @for (d of data; track d) {
    <mat-option *ngIf="!d.parents" [value]="d" (click)="selectOption(d)">{{ d.title || d.name }}</mat-option>
    <mat-option *ngIf="d.parents" [value]="d" (click)="selectOption(d)">{{ d.title }} - {{ d.parents.join(', ') }}</mat-option>
    }
  </mat-select>
  <mat-icon matSuffix>expand_more</mat-icon>
</mat-form-field>

<div *ngIf="valid" class="alert">
  {{ 'invalid.input' | translate }}
</div>
