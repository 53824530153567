import { Component, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import { MatSnackBar } from '@angular/material/snack-bar';

import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { AuthService } from 'src/app/services/ws-user/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MeService } from 'src/app/services/ws-user/me/me.service';
import { PermissionService } from 'src/app/services/permission/permission.service';
import { ApiResponse } from '@model/api-response.model';
import { Auth } from '@model/ws-user/auth.model';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

  @Output() onRecover = new EventEmitter();
  awaitingResponse = false;
  showPassword = true;
  form!: FormGroup;
  keepLoggedIn = false;

  constructor(private formBuilder: FormBuilder, private authService: AuthService, private snackBar: MatSnackBar, private router: Router, private tokenStorage: TokenStorageService, private translate: TranslateService, private meService: MeService, private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl(
        { value: null, disabled: false }, Validators.compose([Validators.pattern('^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9_.-]+[^.]$'),
        Validators.minLength(5), Validators.maxLength(100)])),
      password: new FormControl(
        { value: null, disabled: false }, [Validators.required, Validators.minLength(7), Validators.maxLength(100)]),
      // keepLoggedIn: new FormControl({ value: this.keepLoggedIn, disabled: false }),
    });
  }

  isValid(str: string) {
    return !!this.form.controls[str].errors && !!this.form.controls[str].touched;
  }

  recover() {
    this.onRecover.emit(false);
  }

  onSubmit() {
    this.awaitingResponse = true;
    this.authService.login(this.form.getRawValue()).subscribe({
      next: (response: ApiResponse<Auth>) => {
        this.tokenStorage.setJwt(response.data.jwtToken);
        this.tokenStorage.setRefresh(response.data.refreshToken);
        this.meService.getMeFromApi().subscribe({
          next: (res) => {
            const hasPermission = this.permissionService.getUserPermission('/', res.data);
            if (!hasPermission) {
              this.router.navigate(['/login']);
              this.snackBar.open(this.translate.instant('not.allowed'), "", {
                duration: 3000
              });
              localStorage.clear();
              this.meService.clearCache();
            } else {
              this.router.navigate(['/']);
            }
            this.awaitingResponse = false;
          },
          error: (er) => {
            let errorMessage = 'An unknown error occurred';
            if (er.error && typeof er.error === 'string') {
              errorMessage = er.error;
            } else if (er.error && typeof er.error.message === 'string') {
              errorMessage = er.error.message;
            } else if (er.message) {
              errorMessage = er.message;
            }
            this.snackBar.open(errorMessage, this.translate.instant('close'), { duration: 5000 });
          }
        });
      },
      error: (er) => {
        let errorMessage = 'An unknown error occurred';
        if (er.error && typeof er.error === 'string') {
          errorMessage = er.error;
        } else if (er.error && typeof er.error.message === 'string') {
          errorMessage = er.error.message;
        } else if (er.message) {
          errorMessage = er.message;
        }
        this.snackBar.open(errorMessage, this.translate.instant('close'), { duration: 5000 });
      }
    });
  }
}
