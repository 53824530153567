import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@model/api-response.model';
import { Observable } from 'rxjs/internal/Observable';
import { Auth } from 'src/app/models/auth.model';

import { environment } from 'src/environments/environment';

const URL = `${environment.user}/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  login(user: { email: string, password: string }): Observable<ApiResponse<Auth>> {
    return this.http.post<ApiResponse<Auth>>(URL, { ...user, "keepLoggedIn": true }, { withCredentials: true });
  }

  logout(): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(`${URL}/logout`, { token: localStorage.getItem('jwtToken') }, { withCredentials: true });
  }

  refreshToken(refresh: string): Observable<ApiResponse<Auth>> {
    return this.http.post<ApiResponse<Auth>>(`${URL}/refresh-token`, { token: refresh }, { withCredentials: true });
  }

  revokeToken(): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(`${URL}/revoke-token`, {});
  }

  forgotPassword(email: string): Observable<ApiResponse<string>> {
    localStorage.clear();
    return this.http.post<ApiResponse<string>>(`${URL}/forgot-password`, { email: email });
  }

  verifyEmail(obj: { token: string }): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(`${URL}/verify-email`, obj);
  }

  checkPasswordRecovery(obj: { token: string }): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(`${URL}/check-password-recovery`, obj);
  }

  passwordRecovery(obj: { token: string, newPassword: string }): Observable<ApiResponse<string>> {
    return this.http.post<ApiResponse<string>>(`${URL}/password-recovery`, obj);
  }
}
