import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import Quill from 'quill';

@Component({
  selector: 'app-quill-editor',
  templateUrl: './quill-editor.component.html',
  styleUrl: './quill-editor.component.scss',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => QuillEditorComponent),
    multi: true
  }]
})
export class QuillEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @ViewChild('editor') editorElement!: ElementRef;
  @Input() initialValue: any;
  @Input() valid!: boolean;
  private editor!: Quill;
  private onChange: (value: any) => void = () => { };
  private onTouched: () => void = () => { };

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    const toolbarOptions = [
      // [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline'],
      // ['blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'script': 'super' }],
      [{ 'indent': '+1' }],
      // [{ 'size': ['small', false, 'large', 'huge'] }],
      // [{ 'color': [] },
      // { 'background': [] }
      // ],
      [{ 'align': [] }],
      ['link'],
      ['clean'],
      ['video'],
    ];

    this.editor = new Quill(this.editorElement.nativeElement, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions
      }
    });

    this.writeValue(this.initialValue);
    this.editor.on('text-change', () => {

      this.onChange(this.editor.root.innerHTML);
    });
  }

  writeValue(value: any): void {
    if (this.editor) {
      this.editor.root.innerHTML = value || '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this.editor) {
      this.editor.enable(!isDisabled);
    }
  }
}
