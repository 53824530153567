<mat-form-field floatLabel="always" subscriptSizing="dynamic" class="clean-input">
  <label class="label">{{ title | translate }}</label>
  <mat-chip-grid #dataGrid>
    @for (data of dataSelected; track data) {
    <mat-chip-row class="my-3" (removed)="removeOption(data)">
      {{ data.title || data.name }}
      <button matChipRemove>
        <mat-icon>close</mat-icon>
      </button>
    </mat-chip-row>
    }
  </mat-chip-grid>
  <mat-spinner mode="indeterminate" style="margin:0 auto;" [diameter]="18" *ngIf="loading"></mat-spinner>
  <input [placeholder]="placeholder | translate" #dataInput matInput [matChipInputFor]="dataGrid"
    [matAutocomplete]="data" (input)="dataChanges($event)" />
  <mat-autocomplete requireSelection #data="matAutocomplete" (optionSelected)="addOption($event)">
    @for (data of dataFiltered; track data) {
    <mat-option [value]="data">{{ data.title || data.name }}</mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
