import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-keyword-input',
  templateUrl: './keyword-input.component.html',
  styleUrls: ['./keyword-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeywordInputComponent),
      multi: true,
    }
  ],
})
export class KeywordInputComponent implements ControlValueAccessor, OnInit {
  public _value: string[] = [];
  public disabled: boolean = false;
  @Input() keywords: string[] = [];

  ngOnInit(): void {
    this.keywords = this.keywords || [];
    if (this.keywords.length === 1 && this.keywords[0] === "") {
      this.keywords = [];
    }
  }

  onChanged: (value: string[]) => void = () => { };
  onTouched: () => void = () => { };

  writeValue(val: string[]): void {
    this._value = val;
    this.keywords = val || [];
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addKeyword(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.keywords.push(value);
      this.onChanged(this.keywords);
    }
    this.onTouched();
    event.chipInput!.clear();
  }

  deleteKeyword(keyword: string): void {
    this.keywords = this.keywords.filter(k => k !== keyword);
    this.onChanged(this.keywords);
    this.onTouched();
  }
}
