import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';

import { environment } from 'src/environments/environment';

const URL_ME = `${environment.user}/me`;

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private meCache$?: Observable<any>;

  constructor(private http: HttpClient) { }

  getMeCache(): Observable<any> {
    if (!this.meCache$) {
      this.meCache$ = this.getMeFromApi().pipe(
        shareReplay(1)
      );
    }
    return this.meCache$;
  }

  getMeFromApi(): Observable<any> {
    return this.http.get<any>(URL_ME, { withCredentials: true }).pipe(
      tap(data => {
        this.meCache$ = of(data);
      }));
  }

  clearCache(): void {
    this.meCache$ = undefined;
  }

}
