import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ApiResponse } from '@model/api-response.model';
import { EventManage, EventManageDetail, EventWithParticipants, ParticipantAcceptanceStatus } from '@model/ws-eventshub/event-manage.model';

const URL_MANAGE = `${environment.event}/manage/eventhub`;
const URL_EVENT = `${environment.event}/eventhub`;
const URL_PARTICIPANT = `${environment.event}/manage/participant`;

@Injectable({
  providedIn: 'root'
})
export class EventManageService {

  constructor(private http: HttpClient) { }

  getEvents(): Observable<ApiResponse<EventManage[]>> {
    return this.http.get<ApiResponse<EventManage[]>>(`${URL_MANAGE}/?PageSize=0`, { withCredentials: true });
  }

  getEventById(id: string): Observable<ApiResponse<EventManageDetail>> {
    return this.http.get<ApiResponse<EventManageDetail>>(`${URL_MANAGE}/${id}`, { withCredentials: true });
  }

  getEventParticipants(id: string): Observable<ApiResponse<EventWithParticipants>> {
    return this.http.get<ApiResponse<EventWithParticipants>>(`${URL_MANAGE}/${id}/participant`, { withCredentials: true });
  }

  updateEventParticipant(participant: { id: string, requestParticipant: number }): Observable<any> {
    return this.http.put<ApiResponse<ParticipantAcceptanceStatus>>(`${URL_PARTICIPANT}/${participant.id}`, participant, { withCredentials: true });
  }

  deleteEventParticipant(id: string): Observable<ApiResponse<string>> {
    return this.http.delete<ApiResponse<string>>(`${URL_PARTICIPANT}/${id}`, { withCredentials: true });
  }

  registerEvent(event: EventManageDetail): Observable<ApiResponse<EventManageDetail>> {
    return this.http.post<ApiResponse<EventManageDetail>>(URL_MANAGE, event, { withCredentials: true });
  }

  updateEvent(event: EventManageDetail): Observable<ApiResponse<EventManageDetail>> {
    return this.http.put<ApiResponse<EventManageDetail>>(`${URL_MANAGE}/${event.id}`, event, { withCredentials: true });
  }

  deleteEvent(id: string): Observable<ApiResponse<string>> {
    return this.http.delete<ApiResponse<string>>(`${URL_MANAGE}/${id}`, { withCredentials: true });
  }

  linkEventsToContent(obj: { contentId: string, events: string[] }): Observable<ApiResponse<string>> {
    return this.http.put<ApiResponse<string>>(`${URL_EVENT}/content/${obj.contentId}`, obj, { withCredentials: true });
  }

  getLinkedEvents(contentId: string): Observable<ApiResponse<string[]>> {
    return this.http.get<ApiResponse<string[]>>(`${URL_EVENT}/content/${contentId}`, { withCredentials: true });
  }
}
