import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { forkJoin, Subscription } from 'rxjs';

import { LocationService } from 'src/app/services/ws-common/location/location.service';

@Component({
  selector: 'app-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrl: './autocomplete-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteInputComponent),
      multi: true,
    }
  ],
})
export class AutocompleteInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
  private subscriptions: Subscription = new Subscription();
  dataFiltered: any[] = []; //filteredOpt
  @Input() userLocations: any[] = [];
  @Input() data: any[] = [];//options
  @Input() title!: string;
  @Input() placeholder!: string;
  @Input() fullObject = false;
  @ViewChild('dataInput') dataInput!: ElementRef;

  dataSelected: any[] = [];

  loading = false;

  public _value: any;
  public disabled!: boolean;

  constructor(private locationService: LocationService) { }

  ngOnInit(): void {
    this.populate();
  }

  populate() {
    this.dataSelected = this.data.filter((d: any) => (this.userLocations ?? []).includes(d.id));
  }

  dataChanges(event: any) {
    const inputValue = event.target.value || '';
    this.dataFiltered = this._filterData(inputValue);
  }

  private _filterData(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.data.filter(option => {
      if (option.title) {
        return option.title.toLowerCase().includes(filterValue);
      } else {
        return option.name.toLowerCase().includes(filterValue);
      }
    });
  }

  onChanged: any = () => { };
  onTouched: any = () => { };
  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  addOption(option: any) {
    this.loading = true;
    let selectedOpt = option.option.value;
    if (this.dataSelected.some(d => d.id === selectedOpt.id)) {
      this.loading = false;
      return;
    }
    this.dataSelected.push(selectedOpt);
    let ids = this.dataSelected.map(c => c.id);
    const indexToRemove = this.data.indexOf(selectedOpt);
    if (indexToRemove !== -1) this.data.splice(indexToRemove, 1);
    this.dataInput.nativeElement.value = '';
    this.data = this._filterData(this.dataInput.nativeElement.value);
    this.onTouched();
    this.onChanged(this.fullObject ? this.dataSelected : ids);
    this.loading = false;
  }

  removeOption(dataToRemove: any) {
    this.dataSelected = this.dataSelected.filter(data => data !== dataToRemove);
    this.data.push(dataToRemove);
    let ids = this.dataSelected.map(c => c.id);
    this.onTouched();
    this.onChanged(this.fullObject ? this.dataSelected : ids);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

